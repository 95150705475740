import React from "react";
import "./Blogs.css";

export const Blogs = (props) => {
  return (
    <div id="blogs" className="text-center">
      <div className="container-design">
        <div className="section-title">
          <h2>Blog Content</h2>
        </div>
        <div className="blog-cards-container">
          {props.data
          ? props.data.map((d, i) => (
              <a
              href={d.link}
              target="_blank"
              rel="noopener noreferrer"
              className="blog-card"
            >
              <div className="blog-card-content">
                <h3>{d.topic}</h3>
                <p className="blog-description">
                  {d.subject}
                </p>
                <button className="read-more-btn">Read More</button>
              </div>
            </a>  
          ))
          : "loading"}                               
        </div>
      </div>
    </div>
  );
};


// import React from "react";
// import "./Blogs.css";

// export const Blogs = (props) => {
//   return (
//     <div id="blogs" className="text-center">
//       <div className="container-design">
//         <div className="section-title">
//           <h2>Blog Content</h2>
//         </div>
//         <div className="row" >
//           {/* Blog card-like design */}
//           <a href="/Blogs/how-to-consume-existing-db-container-in-sap-cap.html" target="_blank" rel="noopener noreferrer" className="blog-card">
//             <div className="blog-card-content">
//               <h3>SAP Cloud Application Programming Model</h3>
//               <p>Click here to read about How to consume existing database container in SAP CAP.</p>
//             </div>
//           </a>
//           <a href="/Blogs/how-to-generate-semantic-key-in-sap-cap.html" target="_blank" rel="noopener noreferrer" className="blog-card">
//             <div className="blog-card-content">
//               <h3>SAP Cloud Application Programming Model</h3>
//               <p>Click here to read about How to generate semantic key in SAP CAP.</p>
//             </div>
//           </a>                     
//         </div>
//       </div>
//     </div>
//   );
// };    